import './App.css';
import 'react-phone-number-input/style.css'
import React,{useState,useEffect} from 'react';

import { Link } from 'react-router-dom';
import useGeoLocation from "react-ipgeolocation";
import { Carousel } from 'primereact/carousel';
import Logo from '../../Components/asserts/InstaSpylogo.png'


function Checkout() {
    const [id, setId] = useState("");
    const location = useGeoLocation();
    const I18N_STORAGE_KEY = 'i18nextLng'
    





    

   useEffect(()=>{


    function selectLanguagem(){
      localStorage.setItem(
        I18N_STORAGE_KEY,
        location.country
      )
    }

    selectLanguagem()
    
   })



  return (
    <div>
      <div className='boxinfo'>
     <img width={200}  src={Logo} style={{borderRadius:10}} />
       <div className='bxtextos'>
    

       <div> <h2>🔒 Desbloquear Relatório Completo</h2></div>
       <div className='textit'> <h1>Você vai ter acesso à:</h1></div>

       </div>

       

       <div className="cardfinal">
      <span style={{color:'black',fontSize:20}}> 🔎 Replay dos stories</span>
      <h2 style={{color:'black',marginLeft:10,marginRight:10}}>As pessoas que viram e reviram seus stories</h2>
        </div>

        <div className="cardfinal">
      <span style={{color:'black',fontSize:20}}> 🔎 Rastreio de visitas</span>
      <h2 style={{color:'black',marginLeft:10,marginRight:10}}>Saiba quem está entrando no seu perfil</h2>
        </div>

        <div className="cardfinal">
      <span style={{color:'black',fontSize:20}}> 🔎 Rastreio de menções</span>
      <h2 style={{color:'black',marginLeft:10,marginRight:10}}>Saiba quem são os seguidores que mais falam de você</h2>
        </div>

        <div className="cardfinal">
      <span style={{color:'black',fontSize:20}}>🔎 Quem fica te olhando</span>
      <h2 style={{color:'black',marginLeft:10,marginRight:10}}>Veja quem tirou PRINTS do seu perfil e stories</h2>
        </div>

        <span>E muito mais...</span>

        <span>Nosso sistema de relatórios é o único sistema verdadeiramente funcional do mercado.</span>
   
     <span style={{marginLeft:10,marginRight:10}}>Poderíamos cobrar o quanto você já gastou em saídas, roupas e jantares que nunca deram em nada.</span>
       
       <h1 style={{color:'red'}}>Onde você só se decepcionou.</h1>

       <span>Mas não vamos fazer isso,</span>

       <h2 style={{color:'#FFF'}}>Queremos que você tenha um alvo</h2>

       <span style={{marginLeft:10,marginRight:10}}>Estamos aqui te entregando a única coisa que ainda falta para você, uma direção.</span>
       <p style={{color:'#FFF',marginLeft:10,marginRight:10}}>Não adianta ficar se humilhando por alguém que não te quer, essa é a sua chance de ter certeza.</p>
        

        <span style={{background:'rgb(146 64 14)',width:300,height:50,borderRadius:10,display:'flex'
          ,alignItems:'center',justifyContent:'center',fontWeight:'bold'
        }}>Oferta por tempo limitado</span>

        <div style={{display:'flex',alignItems:'center',flexDirection:'row',justifyContent:'center'
          ,background:'linear-gradient(to right top, #ffd200, #ffb700, #ff9a00, #ff7c00, #ff5b00, #ff471c, #ff2f2d, #ff003b, #ff0058, #ff0076, #ff0094, #ff00b2)'
        }}>
          <div style={{width:200}}>
            <span style={{fontSize:30,fontWeight:'bold'}}>🔓Relatório Completo</span></div>
         
          <div style={{width:200,height:200,display:'flex',flexDirection:'column',justifyContent:'flex-start'
            ,alignItems:'center'
          }}>
            
          <span style={{display:'flex',alignItems:'center',justifyContent:'center',fontWeight:'bold',background:'#FFF',color:'black',width:'100px',height:40,borderRadius:10}}>50% off</span>
          <span style={{marginTop:5}}>de R$197,90 por:</span>
          <h1 style={{marginTop:-10,fontWeight:'900',fontSize:40,color:'#FFF'}}>R$ 59,90</h1>
          <span style={{marginTop:-10}}>à vista ou 2x cartão</span>
          
          
          </div>
        </div>
        
        <h1 style={{color:'#FFF'}}>Acesso vitalício</h1>
        <span>Sem mensalidades, pagamento único</span>
        
        <a href='https://go.perfectpay.com.br/PPU38CP2CHR?&utm_source=diretotk' style={{width:350,height:80,fontSize:20}}>Eu quero o relatório completo</a>
     
      </div>
  
    </div>
  );
}

export default Checkout;
