import "./relatorio.css"

import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import { useEffect, useState,useRef } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { Carousel } from 'primereact/carousel';
import { Message } from 'primereact/message'
import { Flip, toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

export default function Relatorio() {
    const {id,fullname} = useParams();
    const [loading,setLoading] = useState(false)
    const [foto,setFoto] = useState(localStorage.getItem("profilepic_url") ?? '')
    const [imgstorie,setImgstori] = useState('')
    const percentage = 31;
    const [followers,setFollowers] = useState([
      
  
  ])


  const notifys = (msg,ids) => {
      toast(`${msg} Viu seu story por ${ids.substr(0,1)}x `, {
        position:"top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition:Flip

        });
  }



 

    const responsiveOptions = [
      {
          breakpoint: '1400px',
          numVisible: 2,
          numScroll: 1
      },
      {
          breakpoint: '1199px',
          numVisible: 3,
          numScroll: 1
      },
      {
          breakpoint: '767px',
          numVisible: 2,
          numScroll: 1
      },
      {
          breakpoint: '575px',
          numVisible: 1,
          numScroll: 1
      }
  ];

  const productTemplate = (followers) => {
    
    return (
        <div style={{
          width:"100%",height:"350px",backgroundColor:'black',display:'flex',alignItems:'center',flexDirection:'column',
       
          
          }}>

       {
         notifys(followers.full_name,followers.id)
       }
          <div style={{width:"200px",height:"280px",backgroundColor:'black',display:'flex',alignItems:'center',flexDirection:'column'
            ,background:'white',borderRadius:10
          }}>
            {
              followers.username.length  <= 12 ? <div style={{
                background:'white',width:200,backgroundRepeat:'no-repeat',position:'absolute',opacity:0.1,
                }}>
           
                <img  style={{width:200,borderTopLeftRadius:10,borderTopRightRadius:10}} src={followers.profile_pic_url_proxy} />
              
              </div> : <div style={{width:"200px",height:"280px",backgroundColor:'black',display:'flex',alignItems:'center',flexDirection:'column'
            ,background:'white',borderRadius:10
          }}>
              <img  style={{width:200,borderTopLeftRadius:10,borderTopRightRadius:10}} src={followers.profile_pic_url_proxy} />
                    <span style={{marginTop:10,color:'black',fontWeight:'bold'}}>{followers.full_name}</span>
                    <span style={{marginTop:10,color:'grey',fontWeight:'300'}}>@{followers.username}</span>
              </div>
            }
        
     
          </div>
           </div>
      
    );
};


const notify = (followers) => {
  return (
      <div style={{
        width:"100%",height:"30px",display:'flex',alignItems:'center',justifyContent:'center',
     
        
        }}>
<div style={{width:"360px",height:"30px",backgroundColor:'black',display:'flex',alignItems:'left',flexDirection:'column'
            ,background:'white',borderRadius:10
          }}>
        
           <span style={{marginTop:10,color:'black',fontWeight:'bold',fontSize:10,marginLeft:5}}><Message text={`${followers.full_name.substr(0,3)}***`} /></span>

          </div>

         
         </div>
    
  );
};



    useEffect(()=>{
      localStorage.getItem("profilepic_url", foto)
      console.log(foto)

        async function getFlowrs(){
          const options = {
            method: 'GET',
            url: `https://instagram-profile1.p.rapidapi.com/getfollowerswithusername/${id}`,
            headers: {
                'x-rapidapi-key': 'eec6e7ca23msha0bf5b1cea80c8bp1233f8jsn525b66230dd8',
    'x-rapidapi-host': 'instagram-profile1.p.rapidapi.com'
            }
          };
          setLoading(true)
          try {
            const response = await axios.request(options);
            console.log(response.data);
            
            setFollowers(response.data.followers)
            console.log()
       
          
            
          } catch (error) {
            console.error(error);
          }
          }


          async function Stories() {
            const options = {
              method: 'GET',
              url: `https://instagram-profile1.p.rapidapi.com/getstory/${id}`,
              headers: {
                'x-rapidapi-key': 'eec6e7ca23msha0bf5b1cea80c8bp1233f8jsn525b66230dd8',
                'x-rapidapi-host': 'instagram-profile1.p.rapidapi.com'
              }
            };
            setLoading(true)
            try {
              const response = await axios.request(options);
              setImgstori(response.data.story[0].static_image_proxy);
             
            } catch (error) {
              console.error(error);
            }
          }

setTimeout(Stories,1000)
    
          getFlowrs()
   
       
    },[followers])


   
 return (

  <div className="ctRelat">


  <header>

<div className="separador">
 <h1>Prévia</h1>
<span>Não saia dessa página.</span>
</div>
<ToastContainer
position="top-center"
autoClose={3000}
hideProgressBar
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
limit={1}
transition="Flip"

/>

 <div className="sep"></div>

<div className="bxtexts">
<h2>{fullname}</h2>
<strong>@{id}</strong>

</div>
<div className="bximg">
{
foto ? <img src={`${foto.replace(/"/g, '')}`} /> : <div>
<ClipLoader
   color="green"
     size={100}
     aria-label="Loading Spinner"
     data-testid="loader"
   />
</div>
}
</div>
</header>

<div className="geral">
    
		<div className="bxtext">
			<h1>Liberamos apenas UMA PRÉVIA por aparelho</h1>
	
		</div>

</div>

<div className="atv">
  

 {
  imgstorie ?
  
  <div className="atividadess">
  <h2 style={{color:'white'}}>Atividade nos stories</h2>
<div className="atividades">


<img width={150} src={imgstorie} />
<small style={{position:'absolute',zIndex:10,textAlign:'right',bottom:5,right:0,color:'#000000'}}>
<strong><svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-lock inline-block mb-[6px] mr-[5px]"><rect width="18" height="11" x="3" y="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 10 0v4"></path></svg>
<span></span>2 perfis tiraram print</strong>

</small>

</div>

<span style={{color:'gray',fontSize:10,marginTop:10}}>Descubra QUEM foram as pessoas no relatório completo</span>

</div>
  
  :<div> <span style={{color:'black',background:'white',width:'270px',height:'60px',display:'flex',alignItems:'center',justifyContent:'center',borderRadius:10,marginTop:10}}>Você não partilhou nenhum story!</span></div>
 }
  



</div>




<div className="stories">
<h2 style={{color:'white',fontSize:18}}>Reviram seus stories
entre 1 e 4 vezes:</h2>

<div className="card">
<Carousel circular autoplayInterval={3000} value={followers} numVisible={1} numScroll={1} itemTemplate={productTemplate} responsiveOptions={responsiveOptions} />
</div>
<h2 style={{color:'rgb(239, 68, 68)',fontSize:25}}>... e mais 11</h2>
</div>

<div>


</div>

<div style={{display:'flex',width: '100%', height: 250,alignItems:'center',flexDirection:'column',justifyContent:'center',marginTop:20 }}>
<div style={{ width: 100, height: 120}}>
  <CircularProgressbar   value={31} text={`31%`}/>
  
 
</div>
<span style={{textAlign:'center',marginLeft:50,marginRight:50,color:'#FFF', }}>31% dos seus seguidores apresentam sinais de interesse amoroso</span>
</div>

<div style={{display:'flex', width: '100%', height: 250,alignItems:'center',flexDirection:'column',justifyContent:'center',marginTop:20 }}>
<div style={{ width: 100, height: 120}}>
  <CircularProgressbar   value={60} text={`60%`}/>
  
 
</div>
<span style={{textAlign:'center',marginLeft:50,marginRight:50,color:'#FFF'}}>Você é bem vista(o) por 60% dos seus seguidores</span>
</div>

<div style={{display:'flex', width: '100%', height: 150,alignItems:'center',flexDirection:'column',justifyContent:'center',borderRadius:300,
    background:'linear-gradient(to right top, #ffd200, #ffb700, #ff9a00, #ff7c00, #ff5b00, #ff471c, #ff2f2d, #ff003b, #ff0058, #ff0076, #ff0094, #ff00b2)'
 }}>

 <h2 style={{color:'#FFF'}}>15 pessoas</h2>
  
 

<span style={{textAlign:'center',marginLeft:50,marginRight:50,color:'#FFF',fontFamily:'sans-serif'}}>visitaram seu perfil nos últimos dias</span>
</div>

<div style={{display:'flex', width: '100%', height: 150,alignItems:'center',flexDirection:'column',justifyContent:'center',borderRadius:300,marginTop:20,

  background:'linear-gradient(to right top, #ffd200, #ffb700, #ff9a00, #ff7c00, #ff5b00, #ff471c, #ff2f2d, #ff003b, #ff0058, #ff0076, #ff0094, #ff00b2)'
}}>

 <h2 style={{color:'#FFF'}}>5 conversas</h2>
  
 

<span style={{textAlign:'center',marginLeft:50,marginRight:50,color:'#FFF'}}>contém seu nome, 3 positivas e 2 negativas</span>
</div>


<div className="stories">
<h2 style={{color:'white',fontSize:15}}>Visitaram seu perfil essa semana de 2 a 7 vezes:</h2>

<div className="card">
<Carousel circular autoplayInterval={3000} value={followers} numVisible={1} numScroll={1} itemTemplate={productTemplate} responsiveOptions={responsiveOptions} />
</div>
<h2 style={{color:'rgb(239, 68, 68)',fontSize:25}}>... e mais 15</h2>
</div>

<div>


</div>

<div style={{display:'flex', width: '100%', height: 250,alignItems:'center',flexDirection:'column',justifyContent:'center',marginTop:20,
borderTop:'1px solid #FFF'

}}>

<h2 style={{color:'#FFF',fontSize:60,top:0}}>🔒</h2>

<h1 style={{marginTop:-50,color:'#FFF'}}>[SECRETO]</h1>


<span style={{textAlign:'center',marginLeft:50,marginRight:50,color:'#FFF'}}>Visitou seu perfil
12 vezes ontem</span>
</div>


<div style={{display:'flex', width: '100%', height: 250,alignItems:'center',flexDirection:'column',justifyContent:'center',marginTop:20,

borderTop:'1px solid #FFF'
}}>

<h2 style={{color:'#FFF',fontSize:60,top:0}}>🔒</h2>

<h1 style={{marginTop:-50,color:'#FFF'}}>[SECRETO]</h1>


<span style={{textAlign:'center',marginLeft:50,marginRight:50,color:'#FFF'}}>Visitou seu perfil
de madrugada</span>
</div>

<div style={{display:'flex', width: '100%', height: 250,alignItems:'center',flexDirection:'column',justifyContent:'center',marginTop:20,

borderTop:'1px solid #FFF'
}}>

<h2 style={{color:'#FFF',fontSize:60,top:0}}>🔒</h2>

<h1 style={{marginTop:-50,color:'#FFF'}}>[SECRETO]</h1>


<span style={{textAlign:'center',marginLeft:50,marginRight:50,color:'#FFF'}}>Colocou apenas você nos melhores amigos</span>
</div>


<div style={{height:200,display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column'}}>
  <Link to={`/Checkout/${id}`} style={{background:'red',height:80,width:350,display:'flex',alignItems:'center',justifyContent:'center',color:'#FFF',fontWeight:'bold',borderRadius:10
    ,fontSize:20
  }}>Ver relatório completo</Link>
  <span style={{color:'#FFF',marginTop:10,fontWeight:'bold',fontStyle:'italic'}}>Disponível por tempo limitado</span>
</div>

</div>

  );
}