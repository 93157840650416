import { useEffect, useState } from 'react';
import './processando.css'
import ClipLoader from "react-spinners/ClipLoader";
import BarLoader from 'react-spinners/BarLoader'
import { Carousel } from 'primereact/carousel';
import Swal from "sweetalert2";
import { useParams,useNavigate } from "react-router-dom";


export default function Processando() {
    const {id,fullname}= useParams();
    const navigate = useNavigate()
    
    const [textos,setTextos] = useState([
        {
            "name": "Mais de 3 pessoas visitaram seu perfil nos últimos dias",
          
          },
          {
            "name": "5 perfis fakes criados te seguem",
        
          },
          {
            "name": "5 pessoas tiraram print do seu perfil essa semana",
        
          },
          {
            "name": "3 pessoas compartilharam seu perfil com amigos",
        
          }
    ])

    useEffect(()=>{

    
        var i = 0;
        function move() {
          if (i == 0) {
            i = 1;
            var elem = document.getElementById("myBar");
            var progress = document.getElementById("progress")
            var width = 1;
            var ids = setInterval(frame, 300);
            function frame() {
              if (width >= 100) {
                clearInterval(ids);
                i = 0;
              } else {
                width++;
                elem.style.width = width + "%";
                progress.innerHTML = width + "%";
      
                
                 if(elem.style.width =="100%"){
                  Swal.fire({
                    title: `Analise concluida`,
                    icon: "success",
                    confirmButtonText: `Revelar agora`,
                    text: `Vamos revelar elas agora.`,
                   
                  }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                      elem.style.visibility = "hidden";
                 navigate(`/relatorio/${id}/${fullname}`)
                    } else if (result.isDenied) {
                      Swal.fire("Changes are not saved", "", "info");
                    }
                  });
                }
              }
            }
          }
        }
        move()
    },[])

    const responsiveOptions = [
        {
            breakpoint: '1200px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '1199px',
            numVisible: 3,
            numScroll: 1
        },
        {
            breakpoint: '767px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '575px',
            numVisible: 1,
            numScroll: 1
        }
    ];

    

    const productTemplate = (textos) => {
        return (
            <div style={{
    width:"100%",height:100,backgroundColor:'black',display:'flex',alignItems:'center',justifyContent:'center',
             
              
              
              }}>
               
                <div>
                    <h4 style={{fontSize:15,color:'white'}} >{textos.name}</h4>
              
                 
                   
                </div>
            </div>
        );
    };
    
 return (
   <div className='contProcess'>
   
<div className="bxloadi">

<ClipLoader
          color="white"
            size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
          />


<div className="boxlocalisando">
     <div className="boxprogresso">
     <div id="progress"></div>
     <div ><span>Processando dados</span></div> 
   
     </div>
      <div id="myProgress">
  <div id="myBar"></div>
</div>
    </div>
</div>


<div className="card">
<Carousel value={textos} numVisible={1} numScroll={4} responsiveOptions={responsiveOptions} circular
autoplayInterval={3000} itemTemplate={productTemplate} />
        </div>

<div>
    
    <h2 style={{color:'white',fontSize:15,textAlign:'center',marginLeft:30,marginRight:30}}>Nossos programadores criaram essa ferramenta que libera o acesso à informações OCULTAS do seu perfil.</h2>
    </div>        

<div className='bxcarregamento'>
<h1>Vamos revelar elas agora.</h1>

<div className='bxlading'>
    <h2>Carregando informações...</h2>
<BarLoader color='white' width={200}/>
</div>

<div className='bxlading'>
    <h2>Carregando seguidores...</h2>
<BarLoader color='white' width={200}/>
</div>

<div className='bxlading'>
    <h2>Carregando visitas ao perfil...</h2>
<BarLoader color='white' width={200}/>
</div>
<div className='bxlading'>
    <h2>Carregando últimos stories...</h2>
<BarLoader color='white' width={200}/>
</div>



</div>
   </div>
 );
}