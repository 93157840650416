import { Route, Routes,BrowserRouter } from "react-router-dom"
import Checkout from "./Pages/Checkout";
import Dados from "./Pages/Dados";
import Perfil from "./Pages/perfil"
import Relatorio from "./Pages/Relatorio";
import Processando from "./Pages/Processando";


function App() {
  return (
   <BrowserRouter>
    <Routes>


<Route path="/"   element = { <Dados/> } />
<Route path="/perfil/:id"   element = { <Perfil/> } />
<Route path="/processando/:id/:fullname"   element = { <Processando/> } />
<Route path="/relatorio/:id/:fullname"   element = { <Relatorio/> } />
<Route path="/Checkout/:id"   element = { <Checkout/> } />


   </Routes>
   </BrowserRouter>
  );
}

export default App;
