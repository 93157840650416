import "./celular.css"
import { useParams } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { Link } from "react-router-dom";

        

 function Perfil() {

  const {id}= useParams();
  const [foto,setFoto] = useState("")
  const [loading,setLoading] = useState(false)
  const [fullname,setFullname] = useState('')

  

  useEffect(()=>{
 

    async function imagems() {

     
const options = {
  method: 'GET',
  url: `https://instagram-profile1.p.rapidapi.com/getprofile/${id}`,
  headers: {
    'x-rapidapi-key': 'eec6e7ca23msha0bf5b1cea80c8bp1233f8jsn525b66230dd8',
    'x-rapidapi-host': 'instagram-profile1.p.rapidapi.com'
  }
};
setLoading(true)
try {
	const response = await axios.request(options);
	setFoto(response.data.profile_pic_url_hd_proxy)
  localStorage.setItem("profilepic_url", response.data.profile_pic_url_hd_proxy)
  setFullname(response.data.full_name)

  
} catch (error) {
	console.error(error);
}
     
    }
    imagems()

  },[])




   

    


  return (
    <div>
      {
         loading ? <div className="bxtitulo">
         <h1>Esse é o seu perfil?</h1>
        <div className="fotosperfil">{
          foto ?  <img alt="Foto do perfil"  draggable="false" src={foto}/> : <div>
            <ClipLoader
          color="green"
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
          </div>
         }</div>
        {
          
          fullname ?  <div className="bxname"> <span>{fullname}</span> </div>: <div className="bxnameinvisivel"></div>
          
          }

         <h2>@{id}</h2>
     
         <div className="bxBtn">
           <Link to={`/processando/${id}/${fullname}`} className="btnConfimar">Continuar, o perfil está correto</Link>
           <Link to={"/"} className="btnTrocar">Não, quero corrigir</Link>
         </div>
       </div> : <div className="boxloading">
       <ClipLoader
           color="green"
             size={100}
             aria-label="Loading Spinner"
             data-testid="loader"
           />
           <span>Carregando perfil...</span>
           
       </div>
      }
    </div> 

      );

}

export default Perfil;