import './dados.css';
import 'react-phone-number-input/style.css'
import React,{useState,useEffect} from 'react';
import { Link } from 'react-router-dom';
import useGeoLocation from "react-ipgeolocation";
import Logo from '../../Components/asserts/InstaSpylogo.png'

function Dados() {
    const [id, setId] = useState("");
    const location = useGeoLocation();
    const I18N_STORAGE_KEY = 'i18nextLng'
    

  

   useEffect(()=>{
    function selectLanguagem(){
      localStorage.setItem(
        I18N_STORAGE_KEY,
        location.country
      )
    }

    selectLanguagem()
    
   })

  return (
    <div>
    




      <div className='boxinfo'>
     <img src={Logo} width={100} />
       
       <div className='bxbemvindo'>
<h1>Primeiro, qual é o seu perfil?</h1>
<span>Informe seu perfil para iniciar a análise</span>
       </div>

        <span>Seu Instagram (sem o @)</span>
        <input onChange={(e)=>setId(e.target.value)} placeholder='Sem espaços ou @'/>
        <span>Digite sem o "@",exemplo: "cintya"</span>
        <Link to={`/perfil/${id}`}>Continuar</Link>
      </div>
  
    </div>
  );
}

export default Dados;
